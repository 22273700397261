import styled from "styled-components";
import { useState } from "react";

const AddNews = () => {
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [bodyTwo, setBodyTwo] = useState("");
  const [success, setSuccess] = useState(false);
  const [id, setId] = useState("");

  const handleAdd = () => {
    fetch(`${process.env.REACT_APP_API_URL}/news`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        title,
        body,
      }),
    })
      .then((res) => res.json())
      .then((data) => {        setId(data.id)
        setSuccess(true)
      })
      .catch((err) => {
        console.log("error");
      });
  };

  const handleDelete = () => {
    fetch(`${process.env.REACT_APP_API_URL}/shops/${id}`, { method: "DELETE" })
      .then((res) => {
  
        return res.json();
      })
      .then((data) => {
        console.log(data);
        setSuccess(true);
      });
  };

  return (
    <>
      <Wrapper>
        <Input
          type="text"
          placeholder="title"
          value={title}
          onChange={(ev) => setTitle(ev.target.value)}
        />
        <DescriptionInput
          type="text"
          placeholder="body"
          value={body}
          onChange={(ev) => setBody(ev.target.value)}
        />
    

        <Button onClick={handleAdd}>submit</Button>

        {success === true ? (
          <>
            <Text> News added! ID : {id} </Text>
          </>
        ) : (
          <Text>...</Text>
        )}

        <Text>DELETE</Text>

        <Input
          type="text"
          placeholder="News ID"
          value={id}
          onChange={(ev) => setId(ev.target.value)}
        />

        <Button onClick={handleDelete}>submit</Button>
      </Wrapper>
    </>
  );
};

const Input = styled.input`
  width: 400px;
`;

const DescriptionInput = styled.input`
  width: 400px;
  height: 200px;
`;

const Button = styled.button`
  width: 200px;
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Text = styled.h2`
  max-width: 90%;
  font-family: "quicksand-book";
  font-size: 16px;
  text-decoration: none;
`;

export default AddNews;
