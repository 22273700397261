import styled from "styled-components";
import { useState, useEffect, } from "react";
import { Link } from "react-router-dom";


const ItemGridThree = () => {
  const [items, setItems] = useState([]);
  // const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState("all");
  const [filteredItems, setFilteredItems] = useState([]);

  useEffect(() => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/items`)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setItems(data.data);
        setLoading(false);
        console.log(data);
      });
  }, []);

  useEffect(() => {
    if (filter === "all") {
      setFilteredItems(items.filter((item) => item.id > "0"));
    } else if (filter === "books") {
      setFilteredItems(items.filter((item) => item.category === "books"));
    } else if (filter === "music") {
      setFilteredItems(items.filter((item) => item.category === "music"));
    }
  }, [items, filter]);

  const allFilter = () => {
    setFilter("all");
  };

  const booksFilter = () => {
    setFilter("books");
  };

  const musicFilter = () => {
    setFilter("music");
  };

  // Add 'order' property to each item based on its index in the filteredItems array
  const itemsWithOrder = filteredItems.map((item, index) => ({
    ...item,
    order: index,
  }));

  // Sort the items in descending order based on their 'order' property before rendering
  const sortedItems = itemsWithOrder.sort((a, b) => b.order - a.order);

  const displayItems = sortedItems.map((item) => {
    const cover = item.documents.find(
      (document) => document.type === "cover"
    )?.name;

    if (loading) {
      return <p>loading</p>;
    }
    if (loading === false) {
      return (
        <>
          <Wrapper key={Math.floor(Math.random() * 14000000000)}>
            <ImgWrap>
            <StyledImgLink to={`/items/${item.id}`}>
              <Img src={`${process.env.REACT_APP_API_URL}/documents/${cover}`} />
            </StyledImgLink>
            </ImgWrap>
            <StyledLink to={`/items/${item.id}`}>
              <Type>{item.title}</Type>
              <Type>{item.author}</Type>
            </StyledLink>
          </Wrapper>

          {filter === "all" && <Button onClick={booksFilter}>ALL</Button>}

          {filter === "books" && <Button onClick={musicFilter}>BOOKS</Button>}

          {filter === "music" && <Button onClick={allFilter}>MUSIC</Button>}
          
     
        </>
      );
    }
  });

  return (
    <>
      <GridWrapper itemslength={filteredItems.length}>
        {displayItems}
      </GridWrapper>
    </>
  );
};

const GridWrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20%;
  padding-bottom: 5%;

  @media (min-width: 821px) {

    display: grid;
    justify-content: center;
    align-items: ${(props) => (props.itemslength > 5 ? "start" : "start")};
    grid-auto-flow: column;

    grid-template-columns: ${(props) =>
      props.itemslength > 8
        ? "repeat(6, minmax(450px, 450px))"
        : "repeat(4, minmax(450px, 450px))"};


    grid-template-rows: ${(props) =>
      props.itemslength > 4
        ? "repeat(2, 1fr)"
        : "repeat(1, minmax(450px, 450px))"};



    column-gap: 2%;
    row-gap: 5%;
    padding: 20px 400px;
    width: auto;
    margin-top: 1.5%;
    margin-bottom: 1%;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20%;

  @media (min-width: 821px) {
    display: block;
    width: 350px;
    max-height: 40%;
    margin-left: 5%;
    margin-top: 0%
  

  }

  @media (min-width: 992px) {
    display: block;
    width: 350px;
    max-height: 40%;
    margin-left: 5%;
    margin-top: 0%;


  }
`;

const Type = styled.div`
  font-family: "nadia";
  @media (max-width: 821px) {
    margin-top: 1%;
    text-align: center;
  }
`;

const StyledImgLink = styled(Link)``;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 90%;
  &:hover {
    color: #f9bd3b;
    transition: 300ms ease-in-out;
  }

  &.active {
    color: #f9bd3b;
  }

  @media (min-width: 821px) {
    align-items: flex-start;
    max-width: 100%;
  }
`;

const ImgWrap = styled.div `
display: flex;
align-items: flex-end;
justify-content: flex-start;
height: 263px;
`


const Img = styled.img`
  max-height: 263px;
  max-width: 200px;
  filter: drop-shadow(10px 5px 4px grey);
  margin-bottom: 5%;

  @media (max-width: 600px) {
    margin-bottom: 4%;
  }
`;

const Button = styled.button`
  font-family: "quicksand-book";
  font-size: 20px;
  z-index: 9999;
  position: fixed;
  bottom: 0;
  left: 0;
  margin-bottom: 110px;
  margin-left: 22px;

  background-color: lightgray;

  @media (max-width: 821px) {
    display: none;
  }
`;



export default ItemGridThree;
