import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { FaTimes } from "react-icons/fa";

const HamburgerNav = ({ handleNavToggle }) => {
  return (
    <Nav>
           <StyledNavLink onClick={handleNavToggle} to="/">
        umland editions
      </StyledNavLink>
      <StyledNavLink onClick={handleNavToggle} to="/About">
        about
      </StyledNavLink>
      {/* <StyledNavLink onClick={handleNavToggle} to="/News">
        news
      </StyledNavLink> */}
      <StyledNavLink onClick={handleNavToggle} to="/Shops">
        shops
      </StyledNavLink>
      <CloseToggle onClick={handleNavToggle}>
        <FaTimes />
      </CloseToggle>
    </Nav>
  );
};

const Nav = styled.div`
  top: 0;
  right: 0;
  margin-top: 0px;
  height: 100vh;
  width: 100%;
  background-color: lightgray;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 999999;
  font-size: large;

  @media screen and (min-width: 821px) {
    display: none;
  }
`;

const StyledNavLink = styled(NavLink)`
  color: black;
  margin-right: 10px;
  font-family: var(--font-body);
  font-size: 20px;
  font-weight: bold;
  padding: 5px;
  text-decoration: none;
  outline: none;

  &:hover {
    color: #f9bd3b;
  }

  &.active {
    color: #f9bd3b;
  }
`;

const CloseToggle = styled.button`
  position: fixed;
  top: 3%;
  right: 4%;
  background-color: lightgray;

  border: none;

  color: black;
  padding: 0.75rem;
  display: flex;
  place-items: center;
  font-size: 2rem;
  cursor: pointer;
`;

export default HamburgerNav;
