import styled from "styled-components";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { useEffect, useContext } from "react";
import { CurrentUserContext } from "./CurrentUserContext";



const AdminPanel = () => {
  const [title, setTitle] = useState("");
  const [author, setAuthor] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionTwo, setDescriptionTwo] = useState("");
  const [descriptionThree, setDescriptionThree] = useState("");
  const [descriptionFour, setDescriptionFour] = useState("");
  const [category, setCategory] = useState("");
  const [url, setUrl] = useState("");
  const [id, setId] = useState("");
  const [success, setSuccess] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [file, setFile] = useState("");
  const [type, setType] = useState("");
  const [itemId, setItemId] = useState("");

  const {
    currentUser,
    setCurrentUser,
  loggedIn,
  setLoggedIn,
  password,
  setPassword,
  userName,
  setUserName,
} = useContext(CurrentUserContext);


  const [documentSuccess, setDocumentSuccess] = useState(false)


  useEffect(() => {
    const data = JSON.parse(localStorage.getItem('data'))
    
    if (data) {
        setUserName(data.email)
  
    }
}, [])


  const handleDelete = () => {
    fetch(`${process.env.REACT_APP_API_URL}/items/${id}`, { method: "DELETE" })
      .then((res) => {
        console.log(res.body);
        return res.json();
      })
      .then((data) => {
        console.log(data);
        setDeleteSuccess(true);
      });
  };

  const handleAdd = () => {

    const requestBody = {
      id,
      title,
      author,
      description,
      descriptionTwo,
      descriptionThree,
      descriptionFour,
      category,
      url,
    };
  
    console.log("Request Payload:", requestBody);
  
    fetch(`${process.env.REACT_APP_API_URL}/items`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then((res) => res.json())
      .then((data) => {
        setItemId(data.id);
        setId(data.id)
        setSuccess(true);
        setDescription("")
        setDescriptionTwo("")
        setDescriptionThree("")
        setDescriptionFour("")
        setCategory("")
        setUrl("")
         
    console.log(data);
      })
      .catch((err) => {
        console.log("error");
      });
  };

  const handleFileInput = () => {
    const item_id = itemId;
    console.log(file);

    const data = new FormData();
    data.append("type", type);
    data.append("description", description);
    data.append("file", file);
    console.log(data);
    fetch(`${process.env.REACT_APP_API_URL}/documents/${item_id}`, {
      method: "POST",
      body: data,
      // headers: {
      //   "Content-Type": "multipart/form-data",
      // },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setDocumentSuccess(true);
        setFile("")
        setType("")
        setDescription("")
  
        
      })
      .catch((err) => {
        console.log("error");
      });
  };

  return (
    <>
      {userName? (
        <>

      <Wrapper>

        <StyledNavLink to="/admin-news"> ADD NEWS </StyledNavLink>
        <StyledNavLink to="/admin-shop"> ADD SHOP </StyledNavLink>
        <StyledNavLink to="/admin-edit"> EDIT ITEM </StyledNavLink>


        <Text> ADD ITEM </Text>
        <Input
          type="text"
          placeholder="title"
          value={title}
          onChange={(ev) => setTitle(ev.target.value)}
        />

        <Input
          type="text"
          placeholder="author"
          value={author}
          onChange={(ev) => setAuthor(ev.target.value)}
        />

        <DescriptionInput
          type="text"
          placeholder="description"
          value={description}
          onChange={(ev) => setDescription(ev.target.value)}
        />

        <DescriptionInput
          type="text"
          placeholder="description"
          value={descriptionTwo}
          onChange={(ev) => setDescriptionTwo(ev.target.value)}
        />

        <DescriptionInput
          type="text"
          placeholder="description"
          value={descriptionThree}
          onChange={(ev) => setDescriptionThree(ev.target.value)}
        />
        <DescriptionInput
          type="text"
          placeholder="description"
          value={descriptionFour}
          onChange={(ev) => setDescriptionFour(ev.target.value)}
        />
        <Input
          type="text"
          placeholder="category: books or music"
          value={category}
          onChange={(ev) => setCategory(ev.target.value)}
        />
        <Input
          type="text"
          placeholder="url"
          value={url}
          onChange={(ev) => setUrl(ev.target.value)}
        />

 <Input
          type="text"
          placeholder="Item ID"
          value={id}
          onChange={(ev) => setId(ev.target.value)}
        /> 


        <Button onClick={handleAdd}>submit</Button>

        {success === true ? (
          <>
            <Text> Item added! Item ID : {itemId} </Text>
            <Text> ADD DOCUMENT </Text>

            <Input
              type="file"
              // value={file}
              onChange={(ev) => setFile(ev.target.files[0])}
            />

            <Input
              type="text"
              placeholder="description"
              value={description}
              onChange={(ev) => setDescription(ev.target.value)}
            />

            <Input
              type="text"
              placeholder="type - pdf or cover"
              value={type}
              onChange={(ev) => setType(ev.target.value)}
            /> 

             <Button onClick={handleFileInput}> submit</Button>

          </>
        ) : (
          <Text>...</Text>
        )}

{documentSuccess === true ? (
  <>
            <Text> Document added to Item : {itemId} </Text>
  
  
  </>

)
:

<Text>...</Text>}

        <Text>DELETE</Text>

        <Input
          type="text"
          placeholder="Item ID"
          value={id}
          onChange={(ev) => setId(ev.target.value)}
        />

        <Button onClick={handleDelete}>submit</Button>

        {deleteSuccess === true ? (
  <>
            <Text> Item deleted</Text>
  
  
  </>

)
:

<Text>...</Text>}
      </Wrapper>

  </>)
  : 
  
  <p>...</p>
  
}
  </>
  );
};


const Input = styled.input`
  width: 400px;
  margin: 1%;
`;

const DescriptionInput = styled.input`
  width: 400px;
  height: 200px;
  margin: 1%;
`;

const Button = styled.button`
  width: 200px;
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1%;
  margin-bottom: 1%;
`;

const StyledNavLink = styled(NavLink)`
  font-family: "quicksand-book";
  color: black;
`;

const Text = styled.h2`
  max-width: 90%;
  font-family: "quicksand-book";
  font-size: 16px;
  text-decoration: none;
`;

export default AdminPanel;
