import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { useState } from "react";
import HamburgerNav from "./HamburgerNav";
import { FaBars } from "react-icons/fa";
import Logo from "../images/umlandbandcamp.jpg";


const Sidebar = () => {
  const [navToggled, setNavToggled] = useState(false);

  const handleNavToggle = () => {
    if (navToggled === true) setNavToggled(false);
    else setNavToggled(true);
  };

  return (
    <Wrapper>
      {navToggled ? (
        <HamburgerNav handleNavToggle={handleNavToggle} />
      ) : (
        <>
         <MobileBar> <StyledLink to="/"><Umland> umland editions </Umland></StyledLink><Hamburger onClick={handleNavToggle} /></MobileBar>
          <Desktop>
            <Img src={Logo} />
            <Links>
              <StyledLink to="/">
                <Title>umland editions</Title>
              </StyledLink>
              <StyledLink to="About">
                <Title>about</Title>
              </StyledLink>

              {/* <StyledLink to="News">
                <Title>news</Title>
              </StyledLink> */}
              <StyledLink to="Shops">
                <Title>shops</Title>
              </StyledLink>
            </Links>
          </Desktop>
        </>
      )}
    </Wrapper>
  );
};

const Umland = styled.h2`
padding-top: 2%;
`

const MobileBar = styled.div`
max-height: 2%;
display: flex;
flex-direction: row;
justify-content: center;
align-items: bottom;
top: 0;
max-width:100%;

@media (max-width: 300px) {
justify-content: flex-start;
margin-left: 4%;

}

@media (min-width: 821px) {
display: none;
}

@media (min-width: 992px) {
display: none;
}

`;


const Hamburger = styled(FaBars)`
  z-index: 90;
  display: none;
  top: 0;
  color: black;

  @media (max-width: 821px) {
    display: block;
    font-size: 1.9rem;
    top: 0;
    right: 0;
    position: absolute;
    cursor: pointer;
    transform: translate(-100%, 75%);
  }
`;
const Wrapper = styled.div`
  width: 100%;
  font-family: "quicksand-book";
  background-color: lightgrey;
  position: fixed;
  z-index: 1000;

  @media (min-width: 821px) {
    height: 100%;
    width: 220px;
    position: fixed;
    z-index: 1000;
    background-color: lightgrey;
    border-right: 1px solid black;

  }

  @media (min-width: 992px) {
    height: 100%;
    width: 220px;
    position: fixed;
    z-index: 1000;
    background-color: lightgrey;
    border-right: 1px solid black;

  }
`;

const Img = styled.img`
  max-width: 80%;
  padding: 4%;
  margin-top: 10%;
`;

const Desktop = styled.div`
  display: none;

  @media (min-width: 821px) {
    background-color: lightgrey;

    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    border-right: 1px solid black;
    margin-right: 5%;
    position: absolute;
    z-index: 1000;
    width: 100%;
  }

  @media (min-width: 992px) {
    background-color: lightgrey;

    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    border-right: 1px solid black;
    margin-right: 5%;
    position: absolute;
    z-index: 1000;
    width: 100%;
  }
`;
const Title = styled.div`
  font-size: 22px;
  margin-bottom: 5%;
`;

const StyledLink = styled(NavLink)`
  text-decoration: none;
  color: black;

  &:hover {
    color: #f9bd3b;
    transition: none;
  }

  &.active {
    color: #f9bd3b;
  }
`;


const Links = styled.div`
  padding-left: 20%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-decoration: none;
  color: black;

  &:hover {
    color: #f9bd3b;
    transition: none;
  }

  &.active {
    color: #f9bd3b;
  }
`;
export default Sidebar;
