
import styled from "styled-components";
import { Link } from "react-router-dom";
import { FaTimes } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";


const News = () => {

    const navigate = useNavigate();

    const goBack = () => {
		navigate("/");
	}

    const newWindow = (url) => {
        window.open(url, "newwindow");
        return false;
      };

      const [news, setNews] = useState([]);
      const [loading, setLoading] = useState(false);


      useEffect(() => {
        setLoading(true);
        fetch(`${process.env.REACT_APP_API_URL}/news`)
          .then((res) => {
            console.log(res.body);
            return res.json();
          })
          .then((data) => {
            // console.log(data)
            setNews(data.data);
            setLoading(false);
            console.log(news)
          });
      }, [])
    
 if(loading)
      {   return <p>loading</p>;
      }
      else { 
        
        return (
        <>
        {news.map((item) => {
        
      return (
    <Page>
        <Wrapper key={Math.floor(Math.random() * 14000000000)}>
         <CloseToggle onClick={goBack}>
        <FaTimes />
      </CloseToggle>
         <Title> {item.title}</Title>
         <Text> {item.body}</Text>

        </Wrapper>
        </Page>
      );
    })

}</>)
}
};

const Page = styled.div`
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
`


const Wrapper = styled.div`
      height: 30%;
      width: 70%;
      background-color: lightgray;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    padding-top: 15%;
    
      @media (min-width:992px) {
        padding-top: 5%;
    

      };
    `;
    
    const Title = styled.h2`
    max-width: 90%;
    font-family: "nadia";
    font-weight: bold;
    font-size: 18px;
    text-decoration: none;

    @media (min-width: 992px) {
    max-width: 50%;
    }
    `
    
    
    const Text = styled.h2`
    
    max-width: 90%;
    font-family: "nadia";
    font-size: 16px;
    text-decoration: none;
    
    @media (min-width: 992px) {
    max-width: 50%;
    }
    `
    const StyledLink = styled(Link)`
    color: black;
    &:hover {
        color:  #f9bd3b;
        transition: 300ms ease-in-out;
      }
    
      &.active {
        color: #f9bd3b;
      }
    `;

const CloseToggle = styled.button`

display:none;

@media (min-width: 992px) {
position: fixed;
top: 3%;
right: 4%;
background-color: lightgray;

border: none;

color: black;
padding: 0.75rem;
display: flex;
place-items: center;
font-size: 1rem;
cursor: pointer;
}
`;
      

export default News;