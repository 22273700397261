import Sidebar from "./Sidebar";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { FaTimes } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";


const Shops = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate("/");
  };

  const newWindow = (url) => {
    window.open(url, "newwindow");
    return false;
  };

  const [stores, setStores] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/shops`)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setStores(data.data);
        setLoading(false);

      });
  }, []);

  if (loading) {
    return <p>loading</p>;
  } else {
    return (
      <>
        <Wrapper>
          <CloseToggle onClick={goBack}>
            <FaTimes />
          </CloseToggle>
          {stores.map((store) => {
            return (
              <StoreWrap key={Math.floor(Math.random() * 14000000000)}>
                {store.country && <Country> {store.country} </Country>}

                <Name> {store.name} </Name>
                {store.address && <Text> {store.address} </Text>}
                <StyledLink to={store.url} target="blank">
                  {" "}
                  <Text> {store.url} </Text>
                </StyledLink>
              </StoreWrap>
            );
          })}

{/* {Object.keys(storesByCountry).map((country) => (
            <div key={country}>
              <Country>{country}</Country>
              <ul>
                {storesByCountry[country].map((store) => (
                  <StoreWrap key={store.id}>
                    <Name>{store.name}</Name>
                    {store.address && <Text>{store.address}</Text>}
                    <StyledLink to={store.url} target="blank">
                      <Text>{store.url}</Text>
                    </StyledLink>
                  </StoreWrap>
                ))}
              </ul>
            </div>
          ))} */}
        </Wrapper>
      </>
    );
  }
};

const StoreWrap = styled.div`
  width: 100%;
  background-color: lightgray;
  display: flex;
  flex-direction: column;
  margin-left: 3%;
  padding-top: 2%;
  padding-bottom: 7%;

  @media (min-width: 821px) {
    padding-left: 3%;
    max-width: 400px;
  padding-bottom: 0%;

  }

  @media (min-width: 992px) {
    padding-left: 0%;
    width: 400px;
  }
`;
const Wrapper = styled.div`
  height: 95vh;
  background-color: lightgray;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 19%;
  padding-bottom: 5%;

  @media (min-width: 821px) {
    padding-top: 0%;
    margin-left: 225px;
    flex-wrap: wrap;
    padding-bottom: 2%;
  padding-top: 15%;

  }

  @media (min-width: 992px) {
    padding-top: 0%;
    margin-left: 225px;
    flex-wrap: wrap;
    padding-bottom: 2%;
    max-height: 700px;


  }
`;

const Country = styled.h2`
  max-width: 90%;
  font-family: "nadia";
  font-size: 16px;
  text-decoration: none;
  font-weight: 800;

  @media (min-width: 992px) {
    max-width: 50%;
  }
  `

const Name = styled.p `
max-width: 90%;
  font-family: "nadia";
  font-size: 16px;
  text-decoration: none;
  font-weight: 800;

font-style: italic;
margin-bottom: 1%;
  @media (min-width: 992px) {
    max-width: 50%;
  }
`

const Text = styled.h2`
  max-width: 90%;
  font-family: "nadia";
  font-size: 16px;
  text-decoration: none;
  font-weight: 400;
margin-bottom: 1%;
margin-top: 1%;
  @media (min-width: 992px) {
    max-width: 50%;
  }
`;
const StyledLink = styled(Link)`
  color: black;
  &:hover {
    color: #f9bd3b;
    transition: 300ms ease-in-out;
  }

  &.active {
    color: #f9bd3b;
  }
`;

const CloseToggle = styled.button`
  display: none;

  @media (min-width: 992px) {
    position: fixed;
    top: 3%;
    right: 4%;
    background-color: lightgray;

    border: none;

    color: black;
    padding: 0.75rem;
    display: flex;
    place-items: center;
    font-size: 1rem;
    cursor: pointer;
  }
`;

export default Shops;
