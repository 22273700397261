// EditItem.js
import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";

import styled from "styled-components";
import { useNavigate } from "react-router-dom";


const EditItem = () => {
//   const { id } = useParams();
const navigate = useNavigate();

  const [currentItemId, setCurrentItemId] = useState("");
  const [newItemId, setNewItemId] = useState("");
  const [title, setTitle] = useState("");
  const [editSuccess, setEditSuccess] = useState(false);
  const [author, setAuthor] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionTwo, setDescriptionTwo] = useState("");
  const [descriptionThree, setDescriptionThree] = useState("");
  const [descriptionFour, setDescriptionFour] = useState("");
  const [category, setCategory] = useState("");
  const [url, setUrl] = useState("");
  const [file, setFile] = useState("");
  const [type, setType] = useState("");
  const [itemId, setItemId] = useState("");



  // ... other state hooks for item details

  // useEffect(() => {
  //   // Fetch item details based on the current item ID
  //   if (currentItemId) {
  //       fetch(`${process.env.REACT_APP_API_URL}/items/${currentItemId}`)
  //         .then((res) => res.json())
  //         .then((data) => {
  //           // Update state hooks with the fetched item details
  //           setTitle(data.title);
  //           setDescription(data.description)
  //           setDescriptionTwo(data.descriptionTwo)
  //           setDescriptionThree(data.descriptionThree)
  //           setDescriptionFour(data.descriptionFour)
  //           setCategory(data.category)
  //           setUrl(data.category)
  //           setItemId(data.newItemId)
  //           // ... update other state hooks with other details
  //         })
  //     .catch((error) => {
  //       console.error('Error fetching item details:', error);
  //       // Handle error appropriately (e.g., redirect to an error page)
  //     });
  //   }
  // }, [currentItemId]);

  // Your React component

const handleEdit = () => {
  // Implement the logic to update the item on the server
  fetch(`${process.env.REACT_APP_API_URL}/items/${currentItemId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      title,
      author,
      description,
      descriptionTwo,
      descriptionThree,
      descriptionFour,
      category,
      url,
    }),
  })
  .then((res) => {
    if (!res.ok) {
      throw new Error(`HTTP error! Status: ${res.status}`);
    }
    return res.json();
  })
  .then((data) => {
    console.log('Item edited successfully:', data);
    setEditSuccess(true);
    // Optionally, you can redirect to the item details page or another page
    navigate(`/item/${currentItemId}`);
  })
  .catch((error) => {
    console.error('Error editing item:', error.message);
    // Handle error appropriately (e.g., display an error message)
  });
};


  return (
    <Wrapper>
      <h2>Edit Item</h2>
      {/* Render the edit form with input fields and submit button */}
      <input type="text" placeholder="Current Item ID" value={currentItemId} onChange={(e) => setCurrentItemId(e.target.value)} />
      <input type="text" placeholder="New Item ID" value={newItemId} onChange={(e) => setNewItemId(e.target.value)} />
      <Input 
      type="text" 
      value={title} 
      onChange={(e) => setTitle(e.target.value)} />

      <Input
          type="text"
          placeholder="author"
          value={author}
          onChange={(ev) => setAuthor(ev.target.value)}
        />
        <DescriptionInput
          type="text"
          placeholder="description"
          value={description}
          onChange={(ev) => setDescription(ev.target.value)}
        />

        <DescriptionInput
          type="text"
          placeholder="description"
          value={descriptionTwo}
          onChange={(ev) => setDescriptionTwo(ev.target.value)}
        />

        <DescriptionInput
          type="text"
          placeholder="description"
          value={descriptionThree}
          onChange={(ev) => setDescriptionThree(ev.target.value)}
        />
        <DescriptionInput
          type="text"
          placeholder="description"
          value={descriptionFour}
          onChange={(ev) => setDescriptionFour(ev.target.value)}
        />
        <Input
          type="text"
          placeholder="category: books or music"
          value={category}
          onChange={(ev) => setCategory(ev.target.value)}
        />
        <Input
          type="text"
          placeholder="url"
          value={url}
          onChange={(ev) => setUrl(ev.target.value)}
        />




      <button onClick={handleEdit}>Edit Item</button>
      {editSuccess === true ? (
  <>
    <Text>Item edited successfully!</Text>
    {/* Additional UI or logic if needed */}
  </>
) : (
  <Text>...</Text>
)}

    </Wrapper>

  );
};


const Input = styled.input`
  width: 400px;
  margin: 1%;
`;

const DescriptionInput = styled.input`
  width: 400px;
  height: 200px;
  margin: 1%;
`;

const Button = styled.button`
  width: 200px;
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1%;
  margin-bottom: 1%;
`;

const StyledNavLink = styled(NavLink)`
  font-family: "quicksand-book";
  color: black;
`;

const Text = styled.h2`
  max-width: 90%;
  font-family: "quicksand-book";
  font-size: 16px;
  text-decoration: none;
`;
export default EditItem;
