import styled from "styled-components";
import { Link } from "react-router-dom";
import { FaTimes } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const About = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate("/");
  };

  const newWindow = (url) => {
    window.open(url, "newwindow");
    return false;
  };

  return (
    <Wrapper>
      <CloseToggle onClick={goBack}>
        <FaTimes />
      </CloseToggle>
      <Text>
        {" "}
        umland publishes audio and reading materials on sonic cultures, artistic
        creation, and critical reflection, through divers and hybrid textual and
        medial forms. umland is linked to{" "}
        <StyledLink to="http://www.q-o2.be/en/" target="blank">
          {" "}
          Q-O2 workspace{" "}
        </StyledLink>{" "}
        Brussels, and takes its starting points in music and sound art, yet
        embracing the connection with adjacent artistic and scientific
        disciplines.{" "}
      </Text>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100vh;
  background-color: lightgray;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 821px) {
    margin-left: 225px;

  }
`;

const Text = styled.h2`
  max-width: 90%;
  font-family: "nadia";
  font-size: 16px;
  text-decoration: none;

  @media (min-width: 821px) {
    max-width: 50%;
  }
`;
const StyledLink = styled(Link)`

  color: black;
  &:hover {
    color: #f9bd3b;
    transition: 300ms ease-in-out;
  }

  &.active {
    color: #f9bd3b;
  }
`;

const CloseToggle = styled.button`
  display: none;

  @media (min-width: 992px) {
    position: fixed;
    top: 3%;
    right: 4%;
    background-color: lightgray;

    border: none;

    color: black;
    padding: 0.75rem;
    display: flex;
    place-items: center;
    font-size: 1rem;
    cursor: pointer;
  }
`;

export default About;
