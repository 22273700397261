import styled from "styled-components";
import { useEffect, useState, useContext } from "react";
import { CurrentUserContext } from "./CurrentUserContext";
import { Link, NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";



////save to localstorage

const Login = () => {
    const {
        currentUser,
        setCurrentUser,
      loggedIn,
      setLoggedIn,
      password,
      setPassword,
      userName,
      setUserName,
    } = useContext(CurrentUserContext);
    const [invalidUser, setInvalidUser] = useState();
    const [loading, setLoading] = useState(false);
    
    const navigate = useNavigate();

  
    ///login with email and password
    const onSubmitHandler = (e) => {
      e.preventDefault();

    const body = {
        email : userName,
    password : password,
    }
      setLoading(true);
      fetch(`${process.env.REACT_APP_API_URL}/users/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((res) => {

            
            return res.json()})
        .then(async (data) => {
            console.log(data)
          if (data.success !== true) {
            setInvalidUser(true);
            console.log(invalidUser)
            return 
          }
  
          if (data.success === true) {

            setCurrentUser(data);
            await localStorage.setItem("test", "test");

            await window.localStorage.setItem("data", JSON.stringify(body));
        
            setLoggedIn(true);
            setLoading(false);
            setInvalidUser(false);
            navigate("/admin-panel");
            
            
            
  
          }
        });
    };
  
  
    if (loading) {
      return (
        <>
loading
        </>
      );
    } 
      return (
        <>
          <Wrapper>
            <FormContainer>
              <Form >
                <Input
                  type="name"
                  id="name"
                  placeholder="username"
                  maxLength={100}
                  onChange={(e) => setUserName(e.target.value)}
                />
  
                <Input
                  type="password"
                  id="password"
                  placeholder="your password"
                  maxLength={100}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Button onClick={onSubmitHandler}>log in</Button>
              </Form>
            </FormContainer>
          </Wrapper>
  
          {invalidUser && <Div> user not found!</Div>}
          
        
        </>
      );
    }

  

  
  const Div = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    color: black;
  `;
  
  const Input = styled.input`
    margin-top: 10px;
    border-radius: 20px;
    height: 30px;
    width: 200px;
    border: 2px #336699;
  `;
  
  const Form = styled.div`
  
  
    display: flex;
    flex-direction: column;
  justify-content: center;
    color: #669966;
    font-family: var(--font-body);
  `;
  
  const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #669966;
    font-family: var(--font-body);
  `;
  
  const Wrapper = styled.div`
  
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  `;
  
  const StyledNavLink = styled(NavLink)`
    padding: 10px;
    color: #669966;
    font-family: var(--font-body);
    text-decoration: none;
    outline: none;
  
    &:hover {
      color: #336699;
    }
  
    &.active {
      color: #336699;
    }
  `;
  const Button = styled.button`
  margin-top: 10px;
    font-family: var(--font-body);
    background: lightgray;
    height: 32px;
    width: 205px;
    border-radius: 20px;
    border: none;
    cursor: pointer;
    color: black;
  
    &:hover {

      color: #336699;

      transition: 300ms ease-in-out;
    }
  
  `;
  export default Login;