import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import "./index.css";
import About from "./components/About";
import HomePage from "./components/Homepage";
import News from "./components/News";
import Shops from "./components/Shops";
import "./fonts/nadia_serif.ttf";
import "./fonts/Quicksand_Book.otf"
import styled from "styled-components";
import Sidebar from "./components/Sidebar";
import ItemDetail from "./components/ItemDetail";
import Login from "./components/Login";
import AdminPanel from "./components/AdminPanel";
import AddNews from "./components/AddNews";
import AddShop from "./components/AddShop";
import { createGlobalStyle } from 'styled-components'
import EditItem from "./components/EditItem";

const GlobalStyle = createGlobalStyle`
  body {
    background: lightgray;
  }
`


const App  =() => {
  return (
<AppWrap>
<Router>
<div>
   <GlobalStyle />
  <SidebarWrapper>
            <Sidebar/>
            </SidebarWrapper>
          <Routes>
            <Route exact path="/" element={<HomePage />} />
            <Route path="About" element={<About />} />
            <Route path="News" element={<News />} />
            <Route path="Shops" element={<Shops />} />
            <Route exact path="/items/:id" element={<ItemDetail/>} />
            <Route exact path="/admin" element={<Login/>} />
            <Route exact path="/admin-panel" element={<AdminPanel/>} />
            <Route exact path="/admin-news" element={<AddNews/>} />
            <Route exact path="/admin-shop" element={<AddShop/>} />
            <Route path="/admin-edit" element={<EditItem/>} />


            





          </Routes>
          </div>
      </Router>

</AppWrap>
  );
}

const SidebarWrapper = styled.div`
flex-basis: 15%;
align-self: flex-start;

`
const AppWrap = styled.div`
max-width: 100%;
`
export default App;

