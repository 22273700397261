import styled from "styled-components";
import { useState } from "react";


const AddShop = () => {
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [url,setUrl] = useState("");
  const [country, setCountry] = useState("");
  const [success, setSuccess] = useState(false);
  const [id, setId] = useState("");



  const handleAdd = () => {
    fetch(`${process.env.REACT_APP_API_URL}/shops`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name,
        address,
        url, 
        country
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setId(data.id)
        setSuccess(true)
      })
      .catch((err) => {
        console.log("error");
      });
  };

  const handleDelete = () => {
    fetch(`${process.env.REACT_APP_API_URL}/shops/${id}`, { method: "DELETE" })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setSuccess(true);
      });
  };

  

  return (
    <>
      <Wrapper>
      <Input
          type="text"
          placeholder="country"
          value={country}
          onChange={(ev) => setCountry(ev.target.value)}
        />

        <Input
          type="text"
          placeholder="name"
          value={name}
          onChange={(ev) => setName(ev.target.value)}
        />
        <DescriptionInput
          type="text"
          placeholder="address"
          value={address}
          onChange={(ev) => setAddress(ev.target.value)}
        />

        <DescriptionInput
          type="text"
          placeholder="url"
          value={url}
          onChange={(ev) => setUrl(ev.target.value)}
        />

       

        <Button onClick={handleAdd}>submit</Button>

        {success === true ? (
          <>
            <Text> Item added! Item ID : {id} </Text>
            </>)
            :
            (<Text>...</Text>)}


        <Text>DELETE</Text>

<Input
  type="text"
  placeholder="Shop ID"
  value={id}
  onChange={(ev) => setId(ev.target.value)}
/>

<Button onClick={handleDelete}>submit</Button>


      </Wrapper>
    </>
  );
};

const Input = styled.input`
width: 400px;
margin: 1%;

`;

const DescriptionInput = styled.input`
  width: 400px;
  height: 100px;
  margin: 1%;

`;

const Button = styled.button`
  width: 200px;
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1%;
  margin-bottom: 1%;


`;

const Text = styled.h2`
  max-width: 90%;
  font-family: "quicksand-book";
  font-size: 16px;
  text-decoration: none;
`;



export default AddShop;
