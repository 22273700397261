import styled from "styled-components";

import Sidebar from "./Sidebar";
import React, { useState, useRef } from 'react';

import ItemGridThree from "./ItemGrid3";
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";


const HomePage = () => {


  const scrollContainerRef = useRef(null);

  const scrollByAmount = (amount) => {
    const container = scrollContainerRef.current;
    if (container) {
      const currentScrollLeft = container.scrollLeft;
      container.scrollTo({
        left: currentScrollLeft + amount,
        behavior: 'smooth',
      });
    }
  };

  const scrollToStart = () => {
    const container = scrollContainerRef.current;
    if (container) {
      container.scrollTo({
        left: 0,
        behavior: 'smooth',
      });
    }
  };

  return (
    <ScrollContainer ref={scrollContainerRef}>
    <Wrapper>
      {/* Your scrollable content */}
      <ItemGridThree />
    <Arrow onClick={() => scrollByAmount(300)}>
      <BsArrowRight fontSize="2.5rem" />
    </Arrow>
    <BackToStartButton onClick={scrollToStart}>  <BsArrowLeft fontSize="2.5rem" /></BackToStartButton>
  </Wrapper>
    </ScrollContainer>
  )
};

const BackToStartButton = styled.div`
position: fixed;
left: 0;
bottom: 0;
text-size: 3rem;
z-index: 1000;
margin-left: 230px;
margin-bottom: 1%;
cursor: pointer;
z-index: 1000;

@media (max-width: 821px) {
  display: none;
}

`;

const ScrollContainer = styled.div`

/* Add any other styling you need for the scroll container */

overflow-x: scroll;
scrollbar-width: none; /* Hide the scrollbar in Firefox */
-ms-overflow-style: none; /* Hide the scrollbar in IE/Edge */
&::-webkit-scrollbar {
  display: none; /* Hide the scrollbar in Webkit based browsers */


  }
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
background-color: lightgray;

@media (min-width: 821px) {
 
    max-height: 99vh;
    flex-direction: row;
    justify-content: flex-start;
background-color: lightgray;
    
  }

  @media (min-width: 992px) {
    max-width: 3000px;
    max-height: 99vh;
    flex-direction: row;
    justify-content: flex-start;
background-color: lightgray;
    
  }
`;

const Arrow = styled.div`
position: fixed;
right: 0;
bottom: 0;
text-size: 3rem;
z-index: 1000;
margin-right: 1%;
margin-bottom: 1%;
cursor: pointer;

@media (max-width: 821px) {
  display: none;
}

`

const Div = styled.div`
`

export default HomePage;
