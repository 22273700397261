import styled from "styled-components";
import { useParams } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import Img from "../images/umlandbandcamp.jpg";
import { Link } from "react-router-dom";
import { FaTimes } from "react-icons/fa";
import { useNavigate } from "react-router-dom";




const ItemDetail = () => {
  const [item, setItem] = useState({});
  const [loading, setLoading] = useState(true);
  const [pdf, setPdf] = useState(null);

  const navigate = useNavigate();
  const id = useParams();

  const goBack = () => {
    navigate("/");
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/items/${id.id}`)
      .then((res) => res.json())
      .then((data) => {
        setItem(data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, [id]);

  useEffect(() => {
    const pdfDocument = item?.documents?.find((document) => document.type === "pdf");
    setPdf(pdfDocument?.name || null);
  }, [item]);

  if (loading) {
    return <p>Loading...</p>;
  } else {
    const cover = item.documents?.find((document) => document?.type === "cover")?.name;
    const preview = item.documents?.find((document) => document?.type === "pdf")?.name;

    return (
      <>
        
        <Wrapper>
          <CloseToggle onClick={goBack}>
            <FaTimes />
          </CloseToggle>
          <ImageWrapper>
            <ImgDetail src={`${process.env.REACT_APP_API_URL}/documents/${cover}`} />

            {preview && pdf !== null && (
        <StyledLink to={`${process.env.REACT_APP_API_URL}/documents/${pdf}`} target="_blank">
          <Preview>PREVIEW</Preview>
        </StyledLink>
      )}
          </ImageWrapper>
          <TextWrapper>
            <TypeBold>{item.title}</TypeBold>
            <TypeBold>{item.author}</TypeBold>
            <Type>{item.description}</Type>
            <Type>{item.descriptionTwo}</Type>
            <Type>{item.descriptionThree}</Type>
            <Type>{item.descriptionFour}</Type>

            <Type>
              <StyledLink to={item.url} target="blank">
                {" "}
                purchase{" "}
              </StyledLink>
            </Type>
          </TextWrapper>
        </Wrapper>
      
      </>
    );
  }
};



const Wrapper = styled.div`
font-family: "source-sans";
  background-color: lightgray;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding-top: 25%;

  @media (min-width: 821px) {
  padding-top: 30%;
    margin-left: 225px;
    width: 88%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;

 
  }

  @media (min-width: 992px) {
    padding-top: 5%;
    margin-left: 12%;
    width: 88%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    height: 100%;
    height: 100vh;
    position: absolute;

  }
`;
const ImageWrapper = styled.div`
  font-family: "quicksand-book", "source-sans";
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;

  @media (min-width: 821px) {
     display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 80%;

  }

  @media (min-width: 992px) {
    position: fixed;
    max-width: 50%;
    margin-left: 10%;
    max-height: 60%;
    width: 30%;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 3%;
  max-width: 90%;
  margin-top: 5%;
  padding-bottom: 5%;


  @media (min-width: 821px) {
    max-width: 80%;
    max-height: 80%;
    margin-right: 4%;
    padding-left: 0%;
  margin-top: 0%;

  }

  @media (min-width: 992px) {
    max-width: 40%;
    max-height: 90%;
    margin-right: 4%;
    margin-left: 50%;
    padding-left: 0%;
  }
`;

const ImgDetail = styled.img`
  filter: drop-shadow(10px 5px 4px grey);
  max-width: 70%;
  padding: 2%;

  @media (min-width: 821px) {
    max-height: 400px;
    margin-bottom: 5%;
    max-width: 90%;
  }

  @media (min-width: 992px) {
    max-height: 600px;
    margin-bottom: 5%;
    max-width: 90%;
  }
`;

const Type = styled.div`

font-family: "quicksand-book";
  padding-bottom: 2%;
  background-color: lightgray;
`;
const TypeBold = styled.div`
  font-family: "nadia";
  margin-top: 2%;
  padding-bottom: 2%;
  font-weight: bold;
`;

const StyledLink = styled(Link)`
  font-family: "nadia";
  color: black;
  padding-bottom: 9%;

  &:hover {
    color: #f9bd3b;
    transition: 300ms ease-in-out;
  }

  &.active {
    color: #f9bd3b;
  }
`;

const Preview = styled.div`
  font-family: "quicksand-book";
  @media (max-width: 992px) {
    margin-top: 1%;
    margin-bottom: 5%;
  }
`;
const CloseToggle = styled.button`
  display: none;

  @media (min-width: 821px) {
    position: fixed;
    top: 3%;
    right: 4%;
    background-color: lightgray;

    border: none;

    color: black;
    padding: 0.75rem;
    display: flex;
    place-items: center;
    font-size: 1rem;
    cursor: pointer;
  }
`;

export default ItemDetail;
